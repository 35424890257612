// this script implements a scrolling feature for sections with scrollable content (like carousels),
// it checks for the required elements (container, next button, previous button) and attaches smooth scrolling functionality.
// it also uses dynamic querying, so it makes sure the structure of each section is correct to avoid errors

// best practice-wise it's pretty good, because:
// - it uses minimal JS, so it doesn't affect performance (INP)
// - avoids layout shifts during scroll (and this prevents CLS issues)
// - content is loaded via HTML (following LCP best practices)

document.querySelectorAll('.scroll-section').forEach(section => {
    const container = section.querySelector('.scroll-container');
    const nextBtn = section.querySelector('.scroll-btn.next');
    const prevBtn = section.querySelector('.scroll-btn.prev');

    if (!container || !nextBtn || !prevBtn) {
        console.error(`elements missing in section: ${section.getAttribute('data-section')}`);
        return;
    }

    // easing function for smooth scroll
    const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    };

    // custom smooth scroll function
    const smoothScroll = (start, end, duration) => {
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
            const timeElapsed = currentTime - startTime;
            const scroll = easeInOutQuad(timeElapsed, start, end - start, duration);
            container.scrollLeft = scroll;

            if (timeElapsed < duration) {
                requestAnimationFrame(animateScroll);
            }
        };
        requestAnimationFrame(animateScroll);
    };

    // function to scroll container with easing (500ms duration)
    const scrollContainer = (direction, duration = 500) => {
        const itemWidth = container.querySelector('.scroll-item').offsetWidth;
        const gap = parseFloat(window.getComputedStyle(container).getPropertyValue('gap')) || 20;
        const scrollAmount = direction * (itemWidth + gap);

        smoothScroll(container.scrollLeft, container.scrollLeft + scrollAmount, duration);
    };

    // next button click event
    nextBtn.addEventListener('click', () => scrollContainer(1));

    // previous button click event
    prevBtn.addEventListener('click', () => scrollContainer(-1));

    // variables for touch events
    let startX = 0;

    // touch start event
    container.addEventListener('touchstart', (e) => {
        startX = e.touches[0].clientX;
    });

    // touch move event for real-time feedback
    container.addEventListener('touchmove', (e) => {
        const currentX = e.touches[0].clientX;
        const deltaX = startX - currentX;

        if (Math.abs(deltaX) > 40) { // minimum swipe distance to trigger action
            if (deltaX > 0) {
                // Swipe left (next slide)
                scrollContainer(1);  // start scrolling immediately
            } else {
                // Swipe right (previous slide)
                scrollContainer(-1); // start scrolling immediately
            }
            startX = currentX; // to avoid multiple triggers in the same swipe
        }
    });

    // touch end event
    container.addEventListener('touchend', (e) => {
        const endX = e.changedTouches[0].clientX;
        const swipeDistance = startX - endX;

        // set threshold for swipe action
        if (Math.abs(swipeDistance) > 40) { // minimum swipe distance to trigger action
            if (swipeDistance > 0) {
                // swipe left (next slide)
                scrollContainer(1);  // start scrolling immediately
            } else {
                // swipe right (previous slide)
                scrollContainer(-1); // start scrolling immediately
            }
        }
    });
});