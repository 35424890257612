// this script ensures that only one dropdown can be open at a time
// it works by adding an event listener to each checkbox (that toggle the dropdowns)
// so, for example, when a checkbox is checked (therefore a dropdown is opened), the script loops through all the other checkboxes
// and unchecks them, so it closes any other open dropdowns
// this way, only one dropdown will be open at a time, while others close automatically

document.querySelectorAll('.dropdown-checkbox').forEach(checkbox => {
    checkbox.addEventListener('change', function() {
        if (this.checked) {
            document.querySelectorAll('.dropdown-checkbox').forEach(other => {
                if (other !== this) {
                    other.checked = false;
                }
            });
        }
    });
});
