document.querySelectorAll('.accordion-checkbox').forEach((checkbox) => {
    checkbox.addEventListener('change', function () {
        if (this.checked) {
            document
                .querySelectorAll('.accordion-checkbox')
                .forEach((otherCheckbox) => {
                    if (otherCheckbox !== this) {
                        otherCheckbox.checked = false;
                    }
                });
        }
    });
});
